// Importing modules
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Root } from './views/root';
import { Manage } from './views/Manage';
import { Contract } from './views/Contract';
import { MintCrossoverToken } from './views/Manage/MintCrossoverToken';
import { CaskVideo } from './views/Manage/CaskVideo';
import { Nfts } from './views/Manage/Nfts';
import { Contract as GrowthContract } from './views/1155Contract';

import './utils';

import './App.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <Manage />,
        children: [
          {
            path: '/',
            element: <MintCrossoverToken />,
          },
          {
            path: '/cask/video',
            element: <CaskVideo />,
          },
          {
            path: '/nfts',
            element: <Nfts />,
          }
        ]
      },
      {
        path: '/contracts',
        element: <Contract />,
      },
      {
        path: '/1155-contracts',
        element: <GrowthContract />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

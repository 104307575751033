import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select,
  Option,
  Button,
  Input,
  Typography,
} from '@material-tailwind/react';
import { useBackend } from '../../services/backend';
import useLoading from '../../hooks/useLoading';
import { setNfts } from '../../features/app';

export const Nfts = () => {
  const nfts = useSelector((state) => state.app.nfts);
  const [selected, setSelected] = useState({});

  // attributes
  const [sold, setSold] = useState('');
  const [total, setTotal] = useState('');
  const [ownedBy, setOwnedBy] = useState('');

  const { listNfts, updateNftAttributes } = useBackend();
  const { showLoading, hideLoading } = useLoading();
  const dispatch = useDispatch();

  const handleNftChange = (e) => {
    const nftClicked = nfts.find((element) => element.nft_post_id === e);
    if (!nftClicked) return;
    setSelected(nftClicked);
    setSold(nftClicked.nft_sold);
    setTotal(nftClicked.nft_supply);
    setOwnedBy(nftClicked.nft_owner_total_no);
  };

  const handleSoldChange = (e) => {
    setSold(e.target.value);
  };

  const onUpdateNftAttributes = async () => {
    try {
      showLoading();
      const attributes = {
        sold,
      };
      await updateNftAttributes(selected.nft_post_id, attributes);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
      refetch();
    }
  };

  const refetch = useCallback(async () => {
    if (listNfts && dispatch) {
      const { results } = await listNfts({
        marketType: ['growth'],
      });
      dispatch(setNfts(results));
    }
  }, [listNfts, dispatch]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container mx-auto py-10 flex flex-col gap-2">
      <div className="flex gap-5">
        <div className="w-72">
          <Select label="Selected NFT" onChange={handleNftChange}>
            {nfts &&
              nfts.map((c) => {
                return (
                  <Option key={c.nft_post_id} value={c.nft_post_id}>
                    {c.nft_title}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-2">
            <Typography variant="h4" color="blue-gray" className="mb-2">
              {selected && selected.nft_title
                ? `${selected.nft_title}'s Attributes`
                : 'No NFT selected'}
            </Typography>
            <Typography color="blue-gray" className="mb-2">
              Sold Quantity is the quantity of tokens sold to outside customers.
              Some tokens are already sold to other people but still being taken
              into custody by RD.
            </Typography>
            <Typography color="blue-gray" className="mb-2">
              Total Supply: <span className="font-bold">{total}</span>
            </Typography>
            <Typography color="blue-gray" className="mb-2">
              Owned by: <span className="font-bold">{ownedBy} addresses</span>
            </Typography>
            <Typography color="blue-gray" className="mb-2 font-medium">
              The Sold Quantity should not be more than {total}
            </Typography>
            <div className="grow flex flex-col gap-2 basis-0">
              <Input
                label="Sold Quantity"
                value={sold}
                onChange={handleSoldChange}
              />
              <Typography color="blue-gray" className="mb-2">
                (Other attributes would be added later)
              </Typography>
              <Button size="lg" fullWidth onClick={onUpdateNftAttributes}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contractInfo: {},
  casks: [],
  nfts: [],
  isLoading: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setContractInfo: (state, action) => {
      state.contractInfo = action.payload;
    },
    setCasks: (state, action) => {
      state.casks = action.payload;
    },
    setNfts: (state, action) => {
      state.nfts = action.payload;
    },
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setContractInfo, setCasks, setNfts, showLoading, hideLoading } =
  appSlice.actions;

export default appSlice.reducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { useContractRead } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import { utils } from 'ethers';

import { AddressLink } from './AddressLink';
const erc20Abi = require('../../json/erc20.json');

export const ContractInfo = () => {
  const contractInfo = useSelector((state) => state.app.contractInfo);

  const { data: tokenOwner } = useContractRead({
    address: contractInfo['721_token_contract'] && contractInfo['721_token_contract'].address,
    abi: contractInfo['721_token_contract'] && contractInfo['721_token_contract'].abi,
    functionName: 'owner',
  });

  const { data: isPaused } = useContractRead({
    address: contractInfo['721_token_contract'] && contractInfo['721_token_contract'].address,
    abi: contractInfo['721_token_contract'] && contractInfo['721_token_contract'].abi,
    functionName: 'paused',
  });

  const { data: marketplaceAddress } = useContractRead({
    address: contractInfo['721_token_contract'] && contractInfo['721_token_contract'].address,
    abi: contractInfo['721_token_contract'] && contractInfo['721_token_contract'].abi,
    functionName: 'marketplace',
  });

  const { data: marketplaceOwner } = useContractRead({
    address: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].address,
    abi: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].abi,
    functionName: 'owner',
  });

  const { data: tokenAddress } = useContractRead({
    address: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].address,
    abi: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].abi,
    functionName: 'token',
  });

  const { data: paymentTokenAddress } = useContractRead({
    address: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].address,
    abi: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].abi,
    functionName: 'payment_token',
  });

  const { data: paymentTokenName } = useContractRead({
    address: paymentTokenAddress,
    abi: erc20Abi,
    functionName: 'name'
  });

  const { data: paymentTokenDecimals } = useContractRead({
    address: paymentTokenAddress,
    abi: erc20Abi,
    functionName: 'decimals'
  });

  const { data: marketplacePaymentTokenRawBalance } = useContractRead({
    address: paymentTokenAddress,
    abi: erc20Abi,
    functionName: 'balanceOf',
    args: [marketplaceAddress]
  });

  const marketplacePaymentTokenBalance = utils.formatUnits(marketplacePaymentTokenRawBalance ?? 0, paymentTokenDecimals ?? 0);

  return (
    <div className="m-5 text-left">
      <ConnectButton />
      <div className="grid grid-cols-2">
        <div>
          <div>Token Contract</div>
          <div>
            <strong>Address: </strong>
            <AddressLink address={contractInfo['721_token_contract'] && contractInfo['721_token_contract'].address} />
          </div>
          <div>
            <strong>Owner:</strong> <AddressLink address={tokenOwner} />
          </div>
          <div>
            <strong>Marketplace:</strong> <AddressLink address={marketplaceAddress} />
          </div>
          <div>
            <strong>Token Paused?:</strong> {isPaused !== undefined ? isPaused.toString() : 'Loading...'}
          </div>
        </div>
        <div>
          <div>Marketplace Contract</div>
          <div>
            <strong>Address: </strong>
            <AddressLink address={contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].address} />
          </div>
          <div>
            <strong>Owner:</strong> <AddressLink address={marketplaceOwner} /> 
          </div>
          <div>
            <strong>Token:</strong> <AddressLink address={tokenAddress} />
          </div>
          <div>
            <strong>Payment Token:</strong> <AddressLink address={paymentTokenAddress} />
          </div>
          <div>
            <strong>Name: </strong> {paymentTokenName}
          </div>
          <div>
            <strong>Decimals: </strong> {paymentTokenDecimals}
          </div>
          <div>
            <strong>Balance: </strong> {marketplacePaymentTokenBalance}
          </div>
        </div>
      </div>
    </div>
  );
};

import { ThreeDots } from 'react-loader-spinner'
import { useSelector } from 'react-redux'

const LoadingOverlay = () => {
  const isLoading = useSelector((state) => {
    return !!state.app.isLoading;
  });

  return (
    <>
      {isLoading && <div className="fixed inset-0 flex flex-col items-center justify-center bg-black/50 z-[10000]">
        <div>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#1DE8F1"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            visible={true}
          />
        </div>
      </div>}
    </>
  )
}

export default LoadingOverlay

import React from 'react';

import { ContractMethods } from './ContractMethods';
import { ContractInfo } from './ContractInfo';

export function Contract() {
  return (
    <div className="min-h-screen">
      <div className="container mx-auto">
        <ContractInfo />
        <ContractMethods />
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useContract, useAccount, useSigner, useContractRead } from 'wagmi'

import { ContractMethod } from './ContractMethod';

export const ContractMethods = () => {
  const contractInfo = useSelector((state) => state.app.contractInfo);
  const { data: paymentToken } = useContractRead({
    address: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].address,
    abi: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].abi,
    functionName: 'payment_token',
  });
  const { isConnected } = useAccount();
  const { data: signer } = useSigner()
  const tokenContract = useContract({
    address: contractInfo['721_token_contract'] && contractInfo['721_token_contract'].address,
    abi: contractInfo['721_token_contract'] && contractInfo['721_token_contract'].abi,
    signerOrProvider: signer
  });
  const merkatplaceContract = useContract({
    address: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].address,
    abi: contractInfo['721_marketplace_contract'] && contractInfo['721_marketplace_contract'].abi,
    signerOrProvider: signer
  });

  const [minterAddress, setMinterAddress] = useState('');

  const [ownerAddress, setOwnerAddress] = useState('');
  const [marketplaceAddress, setMarketplaceAddress] = useState('');

  const [adminAddress, setAdminAddress] = useState('');
  const [commissionRate, setCommissionRate] = useState(10.0);
  const [royalitiesCommissionRate, setRoyalitiesCommissionRate] = useState(0.0);
  // const canAnyoneMint = false;
  // const [paymentToken, setPaymentToken] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');

  const [withdrawAmount, setWithdrawAmount] = useState('0');

  const isDisabled = !isConnected;

  const handleMinterAddressChange = (event) => {
    setMinterAddress(event.target.value);
  };

  const handleOwnerAddressChange = (event) => {
    setOwnerAddress(event.target.value);
  };

  const handleAdminAddressChange = (event) => {
    setAdminAddress(event.target.value);
  };

  const handleMarketplaceAddressChange = (event) => {
    setMarketplaceAddress(event.target.value);
  };

  const handleCommissionRateChange = (event) => {
    setCommissionRate(parseFloat(event.target.value));
  };

  const handleRoyalitiesCommissionRateChange = (event) => {
    setRoyalitiesCommissionRate(parseFloat(event.target.value));
  };

  const handleWithdrawAmountChange = (event) => {
    setWithdrawAmount(event.target.value);
  };

  const handleTokenAddressChange = (event) => {
    setTokenAddress(event.target.value);
  };

  const pause = async () => {
    try {
      await tokenContract.pause();
    } catch (err) {
      console.error(err);
      alert(err && err.message ? err.message : 'Unidentified Error');
    }
  };

  const onUnpause = async () => {
    try {
      await tokenContract.unpause();
    } catch (err) {
      console.error(err);
      alert(err && err.message ? err.message : 'Unidentified Error');
    }
  };

  const addMinter = async () => {
    try {
      await tokenContract.addMinter(minterAddress);
    } catch (err) {
      console.error(err);
      alert(err && err.message ? err.message : 'Unidentified Error');
    }
  };

  const changeOwner = async () => {
    try {
      await tokenContract.transferOwnership(ownerAddress);
    } catch (err) {
      console.error(err);
      alert(err && err.message ? err.message : 'Unidentified Error');
    }
  };

  const changeMarketplace = async () => {
    try {
      await tokenContract.setMarketplace(marketplaceAddress);
    } catch (err) {
      console.error(err);
      alert(err && err.message ? err.message : 'Unidentified Error');
    }
  };

  const updateAdmin = async () => {
    try {
      await merkatplaceContract
        .updateBot(
          adminAddress
        );
    } catch (err) {
      console.error(err);
      alert(err && err.message ? err.message : 'Unidentified Error');
    }
  };

  const updateToken = async () => {
    try {
      await merkatplaceContract
        .setToken(
          tokenAddress
        );
    } catch (err) {
      console.error(err);
      alert(err && err.message ? err.message : 'Unidentified Error');
    }
  };

  const updateConfig = async () => {
    try {
      await merkatplaceContract
        .updateConfig(
          commissionRate,
          royalitiesCommissionRate,
          paymentToken
        );
    } catch (err) {
      console.error(err);
      alert(err && err.message ? err.message : 'Unidentified Error');
    }
  };

  const withdrawPaymentToken = async () => {
    try {
      await merkatplaceContract
        .withdrawBalanceOfToken(parseInt(withdrawAmount));
    } catch (err) {
      console.error(err);
      alert(err && err.message ? err.message : 'Unidentified Error');
    }
  };

  return (
    <div className="grid grid-cols-2">
      <div>
        <div>Token Contract Methods</div>
        <ContractMethod
          title="Add Minter"
          description="Add minter address so that the minter can mint in CMS."
          isDisabled={isDisabled}
          onClick={addMinter}
        >
          <div className="pb-2">
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="text"
              value={minterAddress}
              onChange={handleMinterAddressChange}
              placeholder="Minter Address"
            />
          </div>
        </ContractMethod>
        <ContractMethod
          title="Pause"
          description="Pause all transfer operation of the contract. This method cannot be triggered when the contract is already paused."
          isDisabled={isDisabled}
          onClick={pause}
        />
        <ContractMethod
          title="Unpause"
          description="Unpause all transfer operation of the contract. This method cannot be triggered when the contract is not paused."
          isDisabled={isDisabled}
          onClick={onUnpause}
        />
        <ContractMethod
          title="Set Marketplace"
          description="Update the marketplace of the token."
          isDisabled={isDisabled}
          onClick={changeMarketplace}
        >
          <div className="pb-2">
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="text"
              value={marketplaceAddress}
              onChange={handleMarketplaceAddressChange}
              placeholder="New Marketplace Address"
            />
          </div>
        </ContractMethod>
        <ContractMethod
          title="Transfer Ownership"
          description="Transfer ownership of the contract."
          isDisabled={isDisabled}
          onClick={changeOwner}
        >
          <div className="pb-2">
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="text"
              value={ownerAddress}
              onChange={handleOwnerAddressChange}
              placeholder="New Owner Address"
            />
          </div>
        </ContractMethod>
      </div>
      <div>
        <div>Marketplace Contract Methods</div>
        <ContractMethod
          title="Update BOT"
          description="Update the BOT of the contract."
          isDisabled={isDisabled}
          onClick={updateAdmin}
        >
          <div className="pb-2">
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2"
              type="text"
              value={adminAddress}
              onChange={handleAdminAddressChange}
              placeholder="New BOT Address"
            />
          </div>
        </ContractMethod>
        <ContractMethod
          title="Update Token"
          description="Update the Token of the contract."
          isDisabled={isDisabled}
          onClick={updateToken}
        >
          <div className="pb-2">
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2"
              type="text"
              value={tokenAddress}
              onChange={handleTokenAddressChange}
              placeholder="New Token Address"
            />
          </div>
        </ContractMethod>
        <ContractMethod
          title="Update Config"
          description="Update configs of the contract."
          isDisabled={isDisabled}
          onClick={updateConfig}
        >
          <div className="pb-2">
            <label className="font-bold">Commission Rate</label>
            <div className="italic">
              The amount that the contract will receive for each sale
            </div>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2"
              type="text"
              value={commissionRate}
              onChange={handleCommissionRateChange}
              placeholder="Commission Rate"
            />
            <label className="font-bold">Royalty Commission Rate</label>
            <div className="italic">
              The amount that the current token owner will receive for each sale
            </div>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2"
              type="number"
              value={royalitiesCommissionRate}
              onChange={handleRoyalitiesCommissionRateChange}
              placeholder="Royalities Commission Rate"
            />
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="text"
              value={paymentToken}
              disabled
              placeholder="USDC Address"
            />
          </div>
        </ContractMethod>
        <ContractMethod
          title="Withdraw USDC"
          description="Withdraw the USDC stored in this contract. This should only be triggered for emergency case!"
          isDisabled={isDisabled}
          onClick={withdrawPaymentToken}
        >
          <div className="pb-2">
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="number"
              value={withdrawAmount}
              onChange={handleWithdrawAmountChange}
              placeholder="Withdraw Amount"
            />
          </div>
        </ContractMethod>
      </div>
    </div>
  );
};

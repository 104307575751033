import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import { ETHERSCAN_PREFIX } from './config';

export const AddressLink = ({ address }) => {
  return (
    <a href={`${ETHERSCAN_PREFIX}/${address}`} target="_blank" rel="noreferrer">
      {address} <FontAwesomeIcon icon={faLink} />
    </a>
  );
};

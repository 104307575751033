import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from "@material-tailwind/react";

export const Login = ({ onSubmit }) => {
  const _yupValidator = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required()
  })

  // hooks
  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(_yupValidator)
  })
  
  return <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[400px]" onSubmit={ handleSubmit(onSubmit) }>
    <div className={'flex flex-col justify-items-center items-censter'}>
      <div>Admin Login</div>
      <div className="mb-6">
        <Input id="email" type="email" label="Email" {...register('email')} />
      </div>
      <div className="mb-6">
        <Input id="password" type="password" label="Password" {...register('password')} />
      </div>
      <div className={'my-8'}>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Login</button>
      </div>
    </div>
  </form>
}
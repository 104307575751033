import { useDispatch } from 'react-redux'
import { showLoading as showLoadingAction, hideLoading as hideLoadingAction } from '../features/app';

const useLoading = () => {
  const dispatch = useDispatch()

  const hideLoading = () => {
    dispatch(hideLoadingAction({}))
  }

  const showLoading = (delay = 30000) => {
    dispatch(showLoadingAction({}))

    if (delay > 0) {
      setTimeout(() => {
        hideLoading()
      }, delay)
    }
  }

  return { showLoading, hideLoading }
}

export default useLoading

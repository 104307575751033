import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Option, Typography } from '@material-tailwind/react';
import { ContractCard } from './ContractCard';
import { ConnectButton, useConnectModal, } from '@rainbow-me/rainbowkit';
import { useBackend } from '../../services/backend';
import { read, utils } from 'xlsx';
import { Button, Input } from '@material-tailwind/react';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import {
  usePrepareContractWrite,
  useContractWrite,
  useAccount,
  useContract,
  useProvider,
} from 'wagmi';
import { setAccessToken } from '../../features/auth';
import { toast } from 'react-toastify';
import useLoading from '../../hooks/useLoading';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const DEPLOYER_ADDRESS = process.env.REACT_APP_DEPLOYER_ADDRESS;
const abi = require('../../json/multipleMintDeployer.json');

export const MintCrossoverToken = () => {
  const casks = useSelector((state) => state.app.casks);
  const contractInfo = useSelector((state) => state.app.contractInfo);
  const { getMe, createNftPost, updateMintedNftPost, logout, updateBindAddress } = useBackend();
  const dispatch = useDispatch();

  const [profile, setProfile] = useState({});
  const [mintData, setMintData] = useState([]);
  const [selectedCask, setSelectedCask] = useState({});
  const [mintedTxId, setMintedTxId] = useState('');
  const [tokenIdBeforeMint, setTokenIdBeforeMint] = useState();
  const [singleMintTitle, setSingleMintTitle] = useState('');
  const [singleMintAddress, setSingleMintAddress] = useState('');
  const [singlePostId, setSinglePostId] = useState('');
  const [singleTokenId, setSingleTokenId] = useState('');

  const handleSingleMintTitleChange = (event) => {
    setSingleMintTitle(event.target.value);
  };

  const handleSingleMintAddressChange = (event) => {
    setSingleMintAddress(event.target.value);
  };

  const handleSinglePostIdChange = (event) => {
    setSinglePostId(event.target.value);
  };

  const handleSingleTokenIdChange = (event) => {
    setSingleTokenId(event.target.value);
  };

  const { isConnected, address } = useAccount();
  const [isCompleted, setIsCompleted] = useState(false);
  const selectedTokenContract = {
    ...contractInfo['721_token_contract'],
    name: '721 Token',
  };

  const [postIds, setPostIds] = useState([]);
  const [toAddresses, setToAddresses] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const {showLoading, hideLoading} = useLoading();

  const { config } = usePrepareContractWrite({
    address: DEPLOYER_ADDRESS,
    abi,
    functionName: 'multipleMint',
    args: [postIds.length, postIds, toAddresses],
  });
  const { writeAsync } = useContractWrite(config);

  const { config: config2 } = usePrepareContractWrite({
    address: contractInfo['721_token_contract'].address,
    abi: contractInfo['721_token_contract'].abi,
    functionName: 'autoMint',
    args: [singlePostId, singleMintAddress],
  });
  const { writeAsync: autoMint } = useContractWrite(config2);
  const provider = useProvider();
  const contract = useContract({
    address: selectedTokenContract.address,
    abi: selectedTokenContract.abi,
    signerOrProvider: provider,
  });
  const { openConnectModal } = useConnectModal();
  const [rebind, setRebind] = useState(false);

  const fetch = useCallback(async () => {
    try {
      const { result } = await getMe();
      setProfile(result);
    } catch (err) {
      console.error(err);
    }
  }, [getMe]);

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCaskChange = (e) => {
    const caskClicked = casks.find((element) => element.ID === e);
    setSelectedCask(caskClicked);
  };

  const handleMintedTxIdChange = (e) => {
    setMintedTxId(e.target.value);
  };

  const handleXlsxFile = async (e) => {
    console.log('reading input file:');
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet);

    setMintData(jsonData);
  };

  const onConfirm = async () => {
    try {
      showLoading();
      const newPostIds = [];
      const newToAddresses = [];

      for (let datum of mintData) {
        const title = datum.post_title;
        const response = await createNftPost(title);
        console.log(response);
        newPostIds.push(response.result);
        newToAddresses.push(datum['to_address']);
        sleep(300);
      }

      setPostIds(newPostIds);
      setToAddresses(newToAddresses);
      hideLoading();

      toast('The NFT Posts were created successfully.');
    } catch (err) {
      hideLoading();
      console.error(err);
    }
  };

  const onCreateSingleNFTPost = async () => {
    try {
      showLoading();      
      const title = singleMintTitle;
      const response = await createNftPost(title);
      console.log(response);
      setSinglePostId(''+response.result);
      hideLoading();

      toast('The NFT Post was created successfully.');
    } catch (err) {
      hideLoading();
      console.error(err);
    }
  };

  const onConfirmMint = async () => {
    if (!isConnected) {
      return alert('Please Connect Your Wallet First');
    }
    if (address !== profile.wallet_address) {
      return alert(`Please Connect Your Bound Wallet Instead of ${address}`);
    }

    try {
      showLoading();
      const latestTokenIdBeforeMint = (await contract.currentId()).toNumber();
      setTokenIdBeforeMint(latestTokenIdBeforeMint);

      const result = await writeAsync();
      console.log(result);

      setMintedTxId(result.hash);
      hideLoading();

      toast(
        'The NFTs are going to be minted. If needed you can speed up the transaction and update the Transaction ID before updating the minted info to the NFTs'
      );
    } catch (err) {
      hideLoading();
      console.error(err);
    }
  };

  const onConfirmSingleMint = async () => {
    if (!isConnected) {
      return alert('Please Connect Your Wallet First');
    }
    if (address !== profile.wallet_address) {
      return alert(`Please Connect Your Bound Wallet Instead of ${address}`);
    }
    if (!singlePostId || !singlePostId.length) {
      return alert('Please Input the Corresponding Post ID!');
    }
    if (!singleMintAddress || !singleMintAddress.length) {
      return alert('Please Input the Mint To Address!');
    }

    try {
      showLoading();
      const latestTokenIdBeforeMint = (await contract.currentId()).toNumber();

      const tx = await autoMint();
      setSingleTokenId('' + (latestTokenIdBeforeMint + 1))
      setMintedTxId(tx.hash);
      hideLoading();
      toast(
        'The mint NFT transaction is submitted.'
      );
    } catch (err) {
      hideLoading();
      toast.error(err.message);
      console.error(err);
    }
  };

  const onConfirmUpdateMint = async () => {
    const cask_id = selectedCask.ID;
    if (!selectedCask || !cask_id) {
      return alert('Please select the cask first!');
    }

    try {
      showLoading();

      const txid = mintedTxId;

      const contract_id = selectedTokenContract.contract_id;

      let id = 1;
      for (const postId of postIds) {
        await updateMintedNftPost(
          postId,
          cask_id,
          contract_id,
          txid,
          tokenIdBeforeMint + id
        );
        id++;
        sleep(300);
      }
      setIsCompleted(true);
      hideLoading();

      toast('The NFTs are updated with the Transaction ID and other info.');
    } catch (err) {
      hideLoading();
      console.error(err);
    }
  };

  const onConfirmUpdateSingleMint = async () => {
    const cask_id = selectedCask.ID;
    if (!selectedCask || !cask_id) {
      return alert('Please select the cask first!');
    }

    if (!singlePostId || !singlePostId.length) {
      return alert('Please Input the Corresponding Post ID!');
    }

    if (!mintedTxId || !mintedTxId.length) {
      return alert('Please Input the Corresponding Transaction ID!');
    }

    if (!singleTokenId || !singleTokenId.length) {
      return alert('Please Input the Corresponding Token ID!');
    }

    try {
      showLoading();

      const txid = mintedTxId;

      const contract_id = selectedTokenContract.contract_id;

      await updateMintedNftPost(
        singlePostId,
        cask_id,
        contract_id,
        txid,
        singleTokenId
      );

      setIsCompleted(true);
      hideLoading();

      toast('The NFT post was updated with the Transaction ID and other info.');
    } catch (err) {
      hideLoading();
      console.error(err);
    }
  };

  const onLogoutPressed = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }

    dispatch(setAccessToken(null));
  };

  const onBindNewAddress = async () => {
    setRebind(true);
    openConnectModal();
  }

  useEffect(() => {
    const _updateBindAddress = async () => {
      try {
        await updateBindAddress(address);
        toast.success('The address was updated')
        setRebind(false);
        fetch();
      } catch (err) {
        toast.error(err.message);
      }
      
    }
    if (rebind && isConnected) {
      _updateBindAddress()
    }

  }, [isConnected, rebind, address, updateBindAddress]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container mx-auto pb-20">
      <div className="flex justify-end pb-2 items-center gap-2">
        <div>Your Bounded Wallet: {profile.wallet_address}</div>
        <ConnectButton />
        <Button onClick={onBindNewAddress}>Bind New Wallet</Button>
        <Button onClick={onLogoutPressed}>Log out</Button>
      </div>
      <div className="flex gap-5">
        <ContractCard contract={selectedTokenContract} />
        <div className="w-72">
          <Select label="Select Cask" onChange={handleCaskChange}>
            {casks.map((c) => {
              return (
                <Option key={c.ID} value={c.ID}>
                  {c.post_title}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
      <Tabs value="single">
        <TabsHeader>
          <Tab key={'single'} value={'single'}>
            Single Mint
          </Tab>
          <Tab key={'multiple'} value={'multiple'}>
            Multiple Mint
          </Tab>
        </TabsHeader>

        <TabsBody>
          <TabPanel key={'single'} value={'single'}>
            <div className="flex gap-2 pt-2">
              <div className="grow flex flex-col gap-2 basis-0">
                <Typography className="text-left">
                  Step 1 - Create Post for NFT
                </Typography>
                <form className="flex flex-col gap-2">
                  <Input
                    variant="outlined"
                    label="Title"
                    value={singleMintTitle}
                    onChange={handleSingleMintTitleChange}
                  />
                </form>
                <div>
                NFT Fullname:{' '}
                  {`${selectedCask.post_title} #${singleMintTitle}`}
                </div>
                <Button size="lg" fullWidth onClick={onCreateSingleNFTPost}>
                  Create NFT Post
                </Button>
              </div>
              <div className="grow flex flex-col gap-2 basis-0">
                <Typography className="text-left">
                  Step 2 - Mint the NFT on Blockchain
                </Typography>
                <form className="flex flex-col gap-2">
                  <Input
                    variant="outlined"
                    label="Post ID"
                    value={singlePostId}
                    onChange={handleSinglePostIdChange}
                  />
                  <Input
                    variant="outlined"
                    label="Mint To Address"
                    value={singleMintAddress}
                    onChange={handleSingleMintAddressChange}
                  />
                </form>
                <Button
                  size="lg"
                  fullWidth
                  onClick={onConfirmSingleMint}
                >
                  Confirm Minting the NFT
                </Button>
              </div>
              <div className="grow flex flex-col gap-2 basis-0">
                <Typography className="text-left">
                  Step 3 - Update Minted NFT Data to Post
                </Typography>
                <form className="flex flex-col gap-2">
                  <Input
                    variant="outlined"
                    label="Post ID"
                    value={singlePostId}
                    onChange={handleSinglePostIdChange}
                  />
                  <Input
                    variant="outlined"
                    label="Token ID"
                    value={singleTokenId}
                    onChange={handleSingleTokenIdChange}
                  />
                  <Input
                    variant="outlined"
                    label="Minted Transaction ID"
                    value={mintedTxId}
                    onChange={handleMintedTxIdChange}
                  />
                </form>
                <Button
                  size="lg"
                  fullWidth
                  onClick={onConfirmUpdateSingleMint}
                >
                  Update Post with Mint Info
                </Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel key={'multiple'} value={'multiple'}>
            <div>
              <div>
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onInput={handleXlsxFile}
                />
              </div>
              {mintData.length ? (
                <>
                  <div>Total {mintData.length} of NTFs will be generated</div>
                  <table className="table-fixed border-spacing-px w-[100%]">
                    <thead>
                      <tr>
                        <th className="bg-blue-100 border text-left px-8 py-4">
                          Full Name
                        </th>
                        <th className="bg-blue-100 border text-left px-8 py-4">
                          To Address
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mintData.map((datum, index) => {
                        return (
                          <tr key={index}>
                            <td className="border px-8 py-2 text-left">{`${selectedCask.post_title} #${datum['post_title']}`}</td>
                            <td className="border px-8 py-2 text-left">
                              {datum['to_address']}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="flex gap-2 pt-2">
                    <div className="grow flex flex-col gap-2 basis-0">
                      <Typography className="text-left">
                        First, create the corresponding NFT Posts by pressing
                        this button. You can check in the WP Admin Panel that
                        these posts exist aftererwards.
                      </Typography>
                      <Button
                        size="lg"
                        fullWidth
                        onClick={onConfirm}
                        disabled={postIds.length}
                      >
                        Create {mintData.length} NFT Posts
                      </Button>
                    </div>
                    <div className="grow flex flex-col gap-2 basis-0">
                      <Typography className="text-left">
                        Then, mint the NFTs to the corresponding addresses.
                        Remember to connect your bound wallet. If you need to
                        bind a new wallet, please go to the WP Admin Panel to
                        update it.{' '}
                      </Typography>
                      <Button
                        size="lg"
                        fullWidth
                        onClick={onConfirmMint}
                        disabled={mintedTxId || !postIds.length}
                      >
                        Confirm Minting of {postIds.length} NFTs
                      </Button>
                    </div>
                    <div className="grow flex flex-col gap-2 basis-0">
                      <Typography className="text-left">
                        Finally, update the transaction info into the NFT Posts.
                        The Transaction ID is pre-filled for you. If you have
                        sped up the transaction, please fill in the updated
                        transaction ID below before pressing the button.{' '}
                      </Typography>
                      <Input
                        label="Minted Transaction ID"
                        value={mintedTxId}
                        onChange={handleMintedTxIdChange}
                      />
                      <Button
                        size="lg"
                        fullWidth
                        onClick={onConfirmUpdateMint}
                        disabled={isCompleted || !mintedTxId}
                      >
                        Confirm Updating Minted NFTs
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
    </div>
  );
};

import React from 'react';

export const ContractMethod = ({
  children,
  title,
  description,
  isDisabled,
  onClick,
}) => {
  return (
    <div className="m-3 rounded-md border text-left">
      <div className="px-2 border-b font-bold text-lg">{title}</div>
      <div className="px-2 pt-2 text-md italic">{description}</div>
      <div className="p-2">
        <div>{children}</div>
        <button
          disabled={isDisabled}
          onClick={onClick}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Write
        </button>
      </div>
    </div>
  );
};

import { useSelector, useDispatch } from 'react-redux';
import { useBackend } from '../../services/backend';

import { setAccessToken } from '../../features/auth';
import { Login } from '../Login';
import { Outlet, NavLink } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';

const MenuLink = ({ to, name }) => {
  return (
    <Typography as="li" color="blue-gray" className="p-1 font-normal">
      <NavLink
        to={to}
        className={({ isActive }) =>
          `flex items-center ${isActive ? 'font-bold' : ''}`
        }
        end
      >
        {name}
      </NavLink>
    </Typography>
  );
};

export const Manage = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const { login } = useBackend();

  const onLoginSubmitted = async (data) => {
    try {
      const { email, password } = data;
      const result = await login(email, password);
      dispatch(setAccessToken(result.access_token));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {isAuthenticated ? (
        <div className="flex">
          <aside
            className="flex w-72 flex-col space-y-2 border-r-2 border-gray-200 bg-white p-2 h-[90.5vh]"
            // style="height: 90.5vh"
            // x-show="asideOpen"
          >
            <MenuLink to="/" name="Mint 721 (Crossover) Tokens" />
            <MenuLink to="/cask/video" name="Casks" />
            <MenuLink to="/nfts" name="1155 NFTs" />
          </aside>
          <div className="p-2 flex-1">
            <Outlet />
          </div>
        </div>
      ) : (
        <div className="container mx-auto flex justify-center">
          <Login onSubmit={onLoginSubmitted} />
        </div>
      )}
    </>
  );
};

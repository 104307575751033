import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import LoadingOverlay from './components/LoadingOverlay';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@material-tailwind/react';

import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, goerli } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

import { PersistGate } from 'redux-persist/integration/react';

import '@rainbow-me/rainbowkit/styles.css';

import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { store, persistor } from './store.js'

const defaultChains = [process.env.REACT_APP_BLOCKCHAIN_ENV==='production' ? mainnet : goerli]

const { chains, provider } = configureChains(defaultChains, [publicProvider()]);

const { connectors } = getDefaultWallets({
  appName: 'RD Multiple Mint',
  chains,
});

const client = createClient({
  // autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
 
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <WagmiConfig client={client}>
        <RainbowKitProvider chains={chains}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <LoadingOverlay />
              <App />
              <ToastContainer />
            </PersistGate>
          </Provider>
        </RainbowKitProvider>
      </WagmiConfig>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

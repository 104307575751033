import axios from 'axios';
import { useSelector } from 'react-redux';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export function useBackend() {
  const accessToken = useSelector((state) => state.auth.accessToken);
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } else {
    axios.defaults.headers.common['Authorization'] = '';
  }

  const getContractInfo = async () => {
    const result = await axios.get('/wp-json/wpsc/v2/contracts');
    return result;
  };

  const listCasks = async () => {
    const result = await axios.get('/wp-json/wpsc/v2/casks');
    return result;
  };

  const updateCaskVideo = async (caskId, animationUrl) => {
    const result = await axios.put(`/wp-json/wpsc/v2/casks/${caskId}/video`, {
      url: animationUrl,
    });
    return result;
  };

  const updateCaskGif = async (caskId, gifUrl) => {
    const result = await axios.put(`/wp-json/wpsc/v2/casks/${caskId}/gif`, {
      url: gifUrl,
    });
    return result;
  };

  const updateCaskLowResVideo = async (caskId, animationUrl) => {
    const result = await axios.put(`/wp-json/wpsc/v2/casks/${caskId}/low_res_video`, {
      url: animationUrl,
    });
    return result;
  };

  const updateCaskAttributes = async (caskId, attributes) => {
    const result = await axios.put(`/wp-json/wpsc/v2/casks/${caskId}/attributes`, {
      ...attributes,
    });
    return result;
  };

  const listNfts = async (params) => {
    const result = await axios.get('/wp-json/wpsc/v2/nfts', {
      params,
    });
    return result;
  };

  const updateNftAttributes = async (nftId, attributes) => {
    const result = await axios.put(`/wp-json/wpsc/v2/nfts/${nftId}/attributes`, {
      ...attributes,
    });
    return result;
  };

  const login = async (email, password) => {
    const result = await axios.post('/wp-json/wpsc/v2/login', {
      email,
      password,
    });
    return result;
  };

  const logout = async (email, password) => {
    const result = await axios.post('/wp-json/wpsc/v2/logout');
    return result;
  };

  const getMe = async () => {
    const result = await axios.get('/wp-json/wpsc/v2/auth/me');
    return result;
  };

  const createNftPost = async (title) => {
    const result = await axios.post('/wp-json/wpsc/v2/nfts', {
      title,
    });
    return result;
  };

  const updateMintedNftPost = async (
    nft_post_id,
    cask_id,
    contract_id,
    txid,
    token_id
  ) => {
    const result = await axios.post(
      `/wp-json/wpsc/v2/nfts/${nft_post_id}/mint`,
      {
        cask_id,
        contract_id,
        txid,
        token_id,
      }
    );
    return result;
  };

  const updateBindAddress = async (address) => {
    const result = await axios.put(
      '/wp-json/wpsc/v2/auth/me/wallet',
      {
        wallet_address: address
      }
    );
    return result;
  }

  return {
    login,
    logout,
    getContractInfo,
    listCasks,
    getMe,
    createNftPost,
    updateMintedNftPost,
    updateBindAddress,
    updateCaskVideo,
    updateCaskGif,
    updateCaskLowResVideo,
    accessToken,
    updateCaskAttributes,
    listNfts,
    updateNftAttributes,
  };
}

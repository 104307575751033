import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from '@material-tailwind/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { ETHERSCAN_PREFIX } from '../Contract/config';

export const ContractCard = ({ contract }) => {
  const href = `${ETHERSCAN_PREFIX}/${contract.address}`;

  return (
    <Card className="w-50">
      <CardBody className="text-center">
        <Typography variant="h5" className="mb-2">
          {contract.name}
        </Typography>
      </CardBody>
      <CardFooter divider className="flex items-end justify-end py-3">
        <a href={href} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faLink} />
        </a>
      </CardFooter>
    </Card>
  );
};

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBLxUpEniUR0XXHCgTbmmR69ELh0EQ4acY",
  authDomain: "raredram-36521.firebaseapp.com",
  projectId: "raredram-36521",
  storageBucket: "raredram-36521.appspot.com",
  messagingSenderId: "836389516740",
  appId: "1:836389516740:web:2a0d36f284f1f7af8ce091"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
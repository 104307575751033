import React from 'react';
import { useSelector } from 'react-redux';
import { useContractRead } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import { utils } from 'ethers';

import { AddressLink } from './AddressLink';
const erc20Abi = require('../../json/erc20.json');

export const ContractInfo = () => {
  const contractInfo = useSelector((state) => state.app.contractInfo);

  const { data: tokenOwner } = useContractRead({
    address: contractInfo['1155_token_contract'] && contractInfo['1155_token_contract'].address,
    abi: contractInfo['1155_token_contract'] && contractInfo['1155_token_contract'].abi,
    functionName: 'owner',
  });

  const { data: isPaused } = useContractRead({
    address: contractInfo['1155_token_contract'] && contractInfo['1155_token_contract'].address,
    abi: contractInfo['1155_token_contract'] && contractInfo['1155_token_contract'].abi,
    functionName: 'paused',
  });

  const marketplaceAddress = contractInfo['1155_marketplace_contract'] && contractInfo['1155_marketplace_contract'].address;

  const { data: marketplaceOwner } = useContractRead({
    address: contractInfo['1155_marketplace_contract'] && contractInfo['1155_marketplace_contract'].address,
    abi: contractInfo['1155_marketplace_contract'] && contractInfo['1155_marketplace_contract'].abi,
    functionName: 'owner',
  });

  const { data: tokenAddress } = useContractRead({
    address: contractInfo['1155_marketplace_contract'] && contractInfo['1155_marketplace_contract'].address,
    abi: contractInfo['1155_marketplace_contract'] && contractInfo['1155_marketplace_contract'].abi,
    functionName: 'erc1155Token',
  });

  const { data: paymentTokenAddress } = useContractRead({
    address: contractInfo['1155_marketplace_contract'] && contractInfo['1155_marketplace_contract'].address,
    abi: contractInfo['1155_marketplace_contract'] && contractInfo['1155_marketplace_contract'].abi,
    functionName: 'paymentToken',
  });

  const { data: paymentTokenName } = useContractRead({
    address: paymentTokenAddress,
    abi: erc20Abi,
    functionName: 'name'
  });

  const { data: paymentTokenDecimals } = useContractRead({
    address: paymentTokenAddress,
    abi: erc20Abi,
    functionName: 'decimals'
  });

  const beneficiary = process.env.REACT_APP_BENEFICIARY;

  const { data: beneficiaryPaymentTokenRawBalance } = useContractRead({
    address: paymentTokenAddress,
    abi: erc20Abi,
    functionName: 'balanceOf',
    args: [beneficiary]
  });

  const beneficiaryPaymentTokenBalance = utils.formatUnits(beneficiaryPaymentTokenRawBalance ?? 0, paymentTokenDecimals ?? 0);

  return (
    <div className="m-5 text-left">
      <ConnectButton />
      <div className="grid grid-cols-2">
        <div>
          <div>1155 Token (Growth) Contract</div>
          <div>
            <strong>Address: </strong>
            <AddressLink address={contractInfo['1155_token_contract'] && contractInfo['1155_token_contract'].address} />
          </div>
          <div>
            <strong>Owner:</strong> <AddressLink address={tokenOwner} />
          </div>
          <div>
            <strong>Token Paused?:</strong> {isPaused !== undefined ? isPaused.toString() : 'Loading...'}
          </div>
        </div>
        <div>
          <div>Marketplace Contract</div>
          <div>
            <strong>Address: </strong>
            <AddressLink address={contractInfo['1155_marketplace_contract'] && contractInfo['1155_marketplace_contract'].address} />
          </div>
          <div>
            <strong>Owner:</strong> <AddressLink address={marketplaceOwner} /> 
          </div>
          <div>
            <strong>Token:</strong> <AddressLink address={tokenAddress} />
          </div>
          <div>
            <strong>Payment Token:</strong> <AddressLink address={paymentTokenAddress} />
          </div>
          <div>
            <strong>Name: </strong> {paymentTokenName}
          </div>
          <div>
            <strong>Decimals: </strong> {paymentTokenDecimals}
          </div>
          <div>
            <strong>Beneficiary: </strong> <AddressLink address={beneficiary} />
          </div>
          <div>
            <strong>Beneficiary Balance: </strong> {beneficiaryPaymentTokenBalance}
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select,
  Option,
  Button,
  Input,
  Typography,
  Card,
  CardBody,
} from '@material-tailwind/react';
import { useBackend } from '../../services/backend';
import useLoading from '../../hooks/useLoading';
import { setCasks } from '../../features/app';
import axios from 'axios';
import { toast } from 'react-toastify';

export const CaskVideo = () => {
  const casks = useSelector((state) => state.app.casks);
  const [selectedCask, setSelectedCask] = useState({});
  const [animationUrl, setAnimationUrl] = useState('');
  const [lowResAnimationUrl, setLowResAnimationUrl] = useState('');
  const [gifUrl, setGifUrl] = useState('');
  const [file, setFile] = useState(null);
  const [lowResFile, setLowResFile] = useState(null);
  const [gifFile, setGifFile] = useState(null);

  // attributes
  const [warehouse, setWarehouse] = useState('');

  const {
    updateCaskVideo,
    updateCaskGif,
    updateCaskLowResVideo,
    accessToken,
    listCasks,
    updateCaskAttributes,
  } = useBackend();
  const { showLoading, hideLoading } = useLoading();
  const dispatch = useDispatch();

  const handleCaskChange = (e) => {
    const caskClicked = casks.find((element) => element.ID === e);
    setSelectedCask(caskClicked);
    setAnimationUrl(caskClicked.animation_url);
    setGifUrl(caskClicked.gif_url);

    setWarehouse(caskClicked.warehouse);
  };

  const handleAnimationUrlChange = (e) => {
    setAnimationUrl(e.target.value);
  };
  const handleGiftUrlChange = (e) => {
    setGifUrl(e.target.value);
  };
  const handleLowResAnimationUrlChange = (e) => {
    setLowResAnimationUrl(e.target.value);
  };

  const handleWarehouseChange = (e) => {
    setWarehouse(e.target.value);
  };

  const onUpdateCaskVideo = async () => {
    try {
      showLoading();
      await updateCaskVideo(selectedCask.ID, animationUrl);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
      refetchCasks();
    }
  };

  const onUpdateCaskGif = async () => {
    try {
      showLoading();
      await updateCaskGif(selectedCask.ID, gifUrl);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
      refetchCasks();
    }
  };

  const onUpdateCaskLowResVideo = async () => {
    try {
      showLoading();
      await updateCaskLowResVideo(selectedCask.ID, lowResAnimationUrl);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
      refetchCasks();
    }
  };

  const onUpdateCaskAttributes = async () => {
    try {
      showLoading();
      const attributes = {
        warehouse,
      };
      await updateCaskAttributes(selectedCask.ID, attributes);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
      refetchCasks();
    }
  };

  const handleVideoFile = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleGifFile = (e) => {
    const selectedFile = e.target.files[0];
    setGifFile(selectedFile);
  };

  const handleLowResVideoFile = (e) => {
    const selectedFile = e.target.files[0];
    setLowResFile(selectedFile);
  };

  const onUploadCaskVideo = async () => {
    onUploadCaskMedia('video');
  };

  const onUploadCaskGif = async () => {
    onUploadCaskMedia('gif');
  };

  const onUploadCaskLowResVideo = async () => {
    onUploadCaskMedia('low_res_video');
  };

  const onUploadCaskMedia = async (type = 'video') => {
    let setVar;
    let fileInvolved;

    switch (type) {
      case 'video':
        setVar = setAnimationUrl;
        fileInvolved = file;
        break;
      case 'gif':
        setVar = setGifUrl;
        fileInvolved = gifFile;
        break;
      case 'low_res_video':
        setVar = setLowResAnimationUrl;
        fileInvolved = lowResFile;
        break;
      default:
        setVar = setAnimationUrl;
        break;
    }

    if (!fileInvolved) {
      toast.error(`Please select a file to upload!`);
      return;
    }

    try {
      showLoading();
      let formData = new FormData();
      formData.append('file', fileInvolved);
      formData.append('cask_id', selectedCask.ID);
      const result = await axios.post(
        'https://us-central1-raredram-36521.cloudfunctions.net/uploadCaskMedia',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setVar(result.url);
      toast(`New File uploaded successfully! 🎉`);
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const isVideoNew =
    !selectedCask.animation_url || selectedCask.animation_url !== animationUrl;
  const isGifNew = !selectedCask.gif_url || selectedCask.gif_url !== gifUrl;
  const isLowResVideoNew =
    !selectedCask.low_res_animation_url ||
    selectedCask.low_res_animation_url !== lowResAnimationUrl;

  const refetchCasks = useCallback(async () => {
    if (listCasks && dispatch) {
      const { results } = await listCasks();
      dispatch(setCasks(results));
    }
  }, [listCasks, dispatch]);

  return (
    <div className="container mx-auto py-10 flex flex-col gap-2">
      <div className="flex gap-5">
        <div className="w-72">
          <Select label="Selected Cask" onChange={handleCaskChange}>
            {casks.map((c) => {
              return (
                <Option key={c.ID} value={c.ID}>
                  {c.post_title}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-2">
            <Card>
              <CardBody>
                <Typography variant="h4" color="blue-gray" className="mb-2">
                  Cask Attributes
                </Typography>
                <div className="grow flex flex-col gap-2 basis-0">
                  <Input
                    label="Warehouse"
                    value={warehouse}
                    onChange={handleWarehouseChange}
                  />
                  <Button size="lg" fullWidth onClick={onUpdateCaskAttributes}>
                    Update Attributes
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className='grid grid-rows-3 gap-2'>
            <Card>
              <CardBody>
                <Typography variant="h4" color="blue-gray" className="mb-2">
                  Cask Video
                </Typography>
                <Typography variant="h6" color="blue-gray" className="mb-2">
                  Cask Video File, displayed on the NFT details page
                </Typography>
                <div className="grow flex flex-col gap-2 basis-0">
                  <Input
                    label="Upload New Video File"
                    type="file"
                    onInput={handleVideoFile}
                    accept="video/*"
                  />
                  <Button size="lg" fullWidth onClick={onUploadCaskVideo}>
                    Upload Video
                  </Button>
                  <Typography>
                    {isVideoNew ? 'New Video Preview' : 'Current Video'}
                  </Typography>
                  <video key={animationUrl} controls>
                    <source src={animationUrl} />
                    Your browser does not support the video tag
                  </video>
                  <Input
                    label="Cask Animation URL"
                    value={animationUrl}
                    onChange={handleAnimationUrlChange}
                  />
                  <Button size="lg" fullWidth onClick={onUpdateCaskVideo}>
                    Update Video URL
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Typography variant="h4" color="blue-gray" className="mb-2">
                  Cask Low Resolution Video
                </Typography>
                <Typography variant="h6" color="blue-gray" className="mb-2">
                  Cask Low Resolution Video File, displayed on the Marketplace
                  Page when gif is blank
                </Typography>
                <div className="grow flex flex-col gap-2 basis-0">
                  <Input
                    label="Upload New Low Res Video File"
                    type="file"
                    onInput={handleLowResVideoFile}
                    accept="video/*"
                  />
                  <Button size="lg" fullWidth onClick={onUploadCaskLowResVideo}>
                    Upload Low Res Video
                  </Button>
                  <Typography>
                    {isLowResVideoNew
                      ? 'New Low Res Video Preview'
                      : 'Current Low Res Video'}
                  </Typography>
                  <video key={lowResAnimationUrl} controls>
                    <source src={lowResAnimationUrl} />
                    Your browser does not support the video tag
                  </video>
                  <Input
                    label="Cask Low Res Animation URL"
                    value={lowResAnimationUrl}
                    onChange={handleLowResAnimationUrlChange}
                  />
                  <Button size="lg" fullWidth onClick={onUpdateCaskLowResVideo}>
                    Update Low Res Video URL
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Typography variant="h4" color="blue-gray" className="mb-2">
                  Cask GIF
                </Typography>
                <Typography variant="h6" color="blue-gray" className="mb-2">
                  Cask GIF File, displayed on the Marketplace page
                </Typography>
                <div className="grow flex flex-col gap-2 basis-0">
                  <Input
                    label="Upload New GIF File"
                    type="file"
                    onInput={handleGifFile}
                    accept="*.gif"
                  />
                  <Button size="lg" fullWidth onClick={onUploadCaskGif}>
                    Upload GIF
                  </Button>
                  <Typography>
                    {isGifNew ? 'New GIF Preview' : 'Current GIF'}
                  </Typography>
                  <img
                    src={gifUrl}
                    alt="GIF to be loaded in Marketplace Page"
                  />
                  <Input
                    label="Cask GIF URL"
                    value={gifUrl}
                    onChange={handleGiftUrlChange}
                  />
                  <Button size="lg" fullWidth onClick={onUpdateCaskGif}>
                    Update GIF URL
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navbar,
  MobileNav,
  Typography,
  IconButton,
  Button,
} from '@material-tailwind/react';
import { Outlet, NavLink } from 'react-router-dom';

import { setContractInfo, setCasks } from '../features/app';
import { setAccessToken, setProfile } from '../features/auth';
import { useBackend } from '../services/backend';

const NavBarLink = ({ to, name }) => {
  return (
    <Typography as="li" color="blue-gray" className="p-1 font-normal">
      <NavLink
        to={to}
        className={({ isActive }) =>
          `flex items-center ${isActive ? 'font-bold' : ''}`
        }
        end
      >
        {name}
      </NavLink>
    </Typography>
  );
};

export const Layout = () => {
  const dispatch = useDispatch();
  const { listCasks, getContractInfo, logout, getMe } = useBackend();

  const [openNav, setOpenNav] = useState(false);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const displayName = useSelector((state) => state.auth.displayName);

  const onLogoutPressed = useCallback(async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }

    dispatch(setAccessToken(null));
  }, [logout, dispatch]);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const loadProfile = useCallback(async () => {
    try {
      const { result } = await getMe();
      dispatch(setProfile(result));
    } catch (err) {
      console.error(err);
      onLogoutPressed();
    }
  }, [getMe, dispatch, onLogoutPressed]);

  useEffect(() => {
    if (isAuthenticated) {
      loadProfile();
    }
  }, [isAuthenticated, loadProfile]);

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <NavBarLink to="/" name="CMS" />
      <NavBarLink to="/contracts" name="721 Contracts" />
      <NavBarLink to="/1155-contracts" name="1155 Contracts" />
      {isAuthenticated && (
        <div className="flex justify-end pb-2 items-center gap-2">
          <div>You are logged in as: {displayName}</div>
          <Button onClick={onLogoutPressed}>Log out</Button>
        </div>
      )}
    </ul>
  );

  const fetchAppInfo = useCallback(async () => {
    if (listCasks && dispatch) {
      console.log('fetchAppInfo');
      const { results } = await listCasks();
      dispatch(setCasks(results));

      const { result } = await getContractInfo();
      dispatch(setContractInfo(result));
    }
  }, [listCasks, dispatch, getContractInfo]);

  useEffect(() => {
    fetchAppInfo();
  }, [fetchAppInfo]);

  return (
    <div className="min-h-screen">
      <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between text-blue-gray-900">
          <Typography
            as="a"
            href="#"
            className="mr-4 cursor-pointer py-1.5 font-medium"
          >
            RAREDRAM Owner Dashboard
          </Typography>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <MobileNav open={openNav}>{navList}</MobileNav>
      </Navbar>
      <Outlet />
      <footer className="fixed bottom-0 p-3 text-center bg-white w-screen">
        <div>Copyright RARE DRAM Limited. {new Date().getFullYear()}.</div>
      </footer>
    </div>
  );
};
